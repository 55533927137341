.payment-page-container{
    padding: 4%;
}
.payment-page-data-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Adjust as needed */
}
.payment-page-card .MuiTypography-root {
    color: #132961;
    font-size: 16px;
    font-weight: 500;
}
.payment-button{
    margin-top: 5%;
    align-content: center;
}